@media only screen and (min-device-width: 350px) and (max-device-width: 991px) {
    .containerComp {
        width: 95%;
        margin-top: 2%;
        margin-left: 2%;
    }

    .accordion {
        width: 100%;
    }
   }